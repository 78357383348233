"use strict";
exports.__esModule = true;
require('./page.css');
exports.default = {
  "avatar": "pf-v5-c-avatar",
  "brand": "pf-v5-c-brand",
  "button": "pf-v5-c-button",
  "contextSelector": "pf-v5-c-context-selector",
  "dirRtl": "pf-v5-m-dir-rtl",
  "drawer": "pf-v5-c-drawer",
  "masthead": "pf-v5-c-masthead",
  "modifiers": {
    "light": "pf-m-light",
    "menu": "pf-m-menu",
    "hidden": "pf-m-hidden",
    "hiddenOnSm": "pf-m-hidden-on-sm",
    "visibleOnSm": "pf-m-visible-on-sm",
    "hiddenOnMd": "pf-m-hidden-on-md",
    "visibleOnMd": "pf-m-visible-on-md",
    "hiddenOnLg": "pf-m-hidden-on-lg",
    "visibleOnLg": "pf-m-visible-on-lg",
    "hiddenOnXl": "pf-m-hidden-on-xl",
    "visibleOnXl": "pf-m-visible-on-xl",
    "hiddenOn_2xl": "pf-m-hidden-on-2xl",
    "visibleOn_2xl": "pf-m-visible-on-2xl",
    "read": "pf-m-read",
    "selected": "pf-m-selected",
    "unread": "pf-m-unread",
    "attention": "pf-m-attention",
    "expanded": "pf-m-expanded",
    "collapsed": "pf-m-collapsed",
    "pageInsets": "pf-m-page-insets",
    "insetNone": "pf-m-inset-none",
    "fill": "pf-m-fill",
    "noFill": "pf-m-no-fill",
    "limitWidth": "pf-m-limit-width",
    "alignCenter": "pf-m-align-center",
    "overflowScroll": "pf-m-overflow-scroll",
    "shadowBottom": "pf-m-shadow-bottom",
    "shadowTop": "pf-m-shadow-top",
    "stickyTop": "pf-m-sticky-top",
    "stickyBottom": "pf-m-sticky-bottom",
    "stickyTopOnSmHeight": "pf-m-sticky-top-on-sm-height",
    "stickyBottomOnSmHeight": "pf-m-sticky-bottom-on-sm-height",
    "stickyTopOnMdHeight": "pf-m-sticky-top-on-md-height",
    "stickyBottomOnMdHeight": "pf-m-sticky-bottom-on-md-height",
    "stickyTopOnLgHeight": "pf-m-sticky-top-on-lg-height",
    "stickyBottomOnLgHeight": "pf-m-sticky-bottom-on-lg-height",
    "stickyTopOnXlHeight": "pf-m-sticky-top-on-xl-height",
    "stickyBottomOnXlHeight": "pf-m-sticky-bottom-on-xl-height",
    "stickyTopOn_2xlHeight": "pf-m-sticky-top-on-2xl-height",
    "stickyBottomOn_2xlHeight": "pf-m-sticky-bottom-on-2xl-height",
    "light_100": "pf-m-light-100",
    "dark_100": "pf-m-dark-100",
    "dark_200": "pf-m-dark-200",
    "padding": "pf-m-padding",
    "noPadding": "pf-m-no-padding",
    "paddingOnSm": "pf-m-padding-on-sm",
    "noPaddingOnSm": "pf-m-no-padding-on-sm",
    "paddingOnMd": "pf-m-padding-on-md",
    "noPaddingOnMd": "pf-m-no-padding-on-md",
    "paddingOnLg": "pf-m-padding-on-lg",
    "noPaddingOnLg": "pf-m-no-padding-on-lg",
    "paddingOnXl": "pf-m-padding-on-xl",
    "noPaddingOnXl": "pf-m-no-padding-on-xl",
    "paddingOn_2xl": "pf-m-padding-on-2xl",
    "noPaddingOn_2xl": "pf-m-no-padding-on-2xl",
    "light_200": "pf-m-light-200"
  },
  "nav": "pf-v5-c-nav",
  "notificationBadge": "pf-v5-c-notification-badge",
  "page": "pf-v5-c-page",
  "pageDrawer": "pf-v5-c-page__drawer",
  "pageHeader": "pf-v5-c-page__header",
  "pageHeaderBrand": "pf-v5-c-page__header-brand",
  "pageHeaderBrandLink": "pf-v5-c-page__header-brand-link",
  "pageHeaderBrandToggle": "pf-v5-c-page__header-brand-toggle",
  "pageHeaderNav": "pf-v5-c-page__header-nav",
  "pageHeaderTools": "pf-v5-c-page__header-tools",
  "pageHeaderToolsGroup": "pf-v5-c-page__header-tools-group",
  "pageHeaderToolsItem": "pf-v5-c-page__header-tools-item",
  "pageMain": "pf-v5-c-page__main",
  "pageMainBody": "pf-v5-c-page__main-body",
  "pageMainBreadcrumb": "pf-v5-c-page__main-breadcrumb",
  "pageMainDrawer": "pf-v5-c-page__main-drawer",
  "pageMainGroup": "pf-v5-c-page__main-group",
  "pageMainNav": "pf-v5-c-page__main-nav",
  "pageMainSection": "pf-v5-c-page__main-section",
  "pageMainSubnav": "pf-v5-c-page__main-subnav",
  "pageMainTabs": "pf-v5-c-page__main-tabs",
  "pageMainWizard": "pf-v5-c-page__main-wizard",
  "pageSidebar": "pf-v5-c-page__sidebar",
  "pageSidebarBody": "pf-v5-c-page__sidebar-body",
  "themeDark": "pf-v5-theme-dark"
};