"use strict";
exports.__esModule = true;
require('./nav.css');
exports.default = {
  "dirRtl": "pf-v5-m-dir-rtl",
  "divider": "pf-v5-c-divider",
  "menu": "pf-v5-c-menu",
  "menuContent": "pf-v5-c-menu__content",
  "menuItem": "pf-v5-c-menu__item",
  "menuItemToggleIcon": "pf-v5-c-menu__item-toggle-icon",
  "menuListItem": "pf-v5-c-menu__list-item",
  "modifiers": {
    "flyout": "pf-m-flyout",
    "top": "pf-m-top",
    "left": "pf-m-left",
    "current": "pf-m-current",
    "drillUp": "pf-m-drill-up",
    "horizontal": "pf-m-horizontal",
    "tertiary": "pf-m-tertiary",
    "horizontalSubnav": "pf-m-horizontal-subnav",
    "light": "pf-m-light",
    "scrollable": "pf-m-scrollable",
    "overflowHidden": "pf-m-overflow-hidden",
    "expandable": "pf-m-expandable",
    "expanded": "pf-m-expanded",
    "drilldown": "pf-m-drilldown",
    "hover": "pf-m-hover",
    "start": "pf-m-start"
  },
  "nav": "pf-v5-c-nav",
  "navItem": "pf-v5-c-nav__item",
  "navLink": "pf-v5-c-nav__link",
  "navList": "pf-v5-c-nav__list",
  "navScrollButton": "pf-v5-c-nav__scroll-button",
  "navSection": "pf-v5-c-nav__section",
  "navSectionTitle": "pf-v5-c-nav__section-title",
  "navSubnav": "pf-v5-c-nav__subnav",
  "navToggle": "pf-v5-c-nav__toggle",
  "navToggleIcon": "pf-v5-c-nav__toggle-icon",
  "themeDark": "pf-v5-theme-dark"
};