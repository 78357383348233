import { Title, Text, TextVariants, PageSection, Button, ClipboardCopy, ClipboardCopyVariant } from "@patternfly/react-core";
import * as React from "react";
import { Trans, useTranslation } from 'react-i18next';

const RegisterNodesPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    const lmnUrl = "https://api.linbit.com/public/linbit-manage-node.py";

    return (
        <PageSection className="centered" variant="light">
            <Title headingLevel="h1">{t('register-nodes.header')}</Title><br />
            <div className="centered" style={{textAlign: "center"}}><Button component="a" href={lmnUrl} download target="_blank">{t('register-nodes.download-button-text')}</Button></div>
            <br />
            <Text component={TextVariants.p}><Trans i18nKey='register-nodes.we-invite-you'>
                We invite you to use our <a href={lmnUrl} download target="_blank">commandline tool</a> to configure the repository and register the node with Linbit's contract database.
            </Trans></Text>
            <Text component={TextVariants.p} style={{ paddingBottom: "5px" }}>{t('register-nodes.to-use-it-save')}</Text>
            <Text component={TextVariants.pre}>
                <ClipboardCopy isReadOnly variant={ClipboardCopyVariant.inlineCompact} hoverTip="Copy to clipboard" clickTip="Copied">
                    chmod u+x /tmp/linbit-manage-node.py
                </ClipboardCopy>
            </Text><br />
            <Text component={TextVariants.p} style={{ paddingBottom: "5px" }}><strong>{t('register-nodes.interactive-mode')}</strong></Text>
            <Text component={TextVariants.pre}>
                <ClipboardCopy isReadOnly variant={ClipboardCopyVariant.inlineCompact} hoverTip="Copy to clipboard" clickTip="Copied">
                    /tmp/linbit-manage-node.py
                </ClipboardCopy>
            </Text><br />
            <Text component={TextVariants.p}><strong>{t('register-nodes.non-interactive-mode')}</strong></Text>
            <Text component={TextVariants.p} style={{ paddingBottom: "5px" }}>{t('register-nodes.non-interactive-description')}</Text>
            <Text component={TextVariants.pre}>
                <ClipboardCopy isReadOnly variant={ClipboardCopyVariant.inlineCompact} hoverTip="Copy to clipboard" clickTip="Copied">
                    /tmp/linbit-manage-node.py -p
                </ClipboardCopy>
            </Text>
            <Text component={TextVariants.p} style={{ paddingTop: "5px" }}>{t('register-nodes.script-description')}</Text>
        </PageSection >
    )
}

export { RegisterNodesPage }
