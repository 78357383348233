{
    "translation": {
        "common": {
            "add": "Hinzufügen",
            "save": "Speichern",
            "clear": "Leeren",
            "back": "Zurück",
            "email": "Email",
            "technical": "Technisch",
            "sales": "Verkauf",
            "error": "Fehler",
            "yes": "Ja",
            "cancel": "Abbrechen"
        },
        "contracts": {
            "contracts": "Verträge",
            "actions": "Aktionen",
            "support-level": "Supportstufe",
            "customer": "Kunde",
            "created": "Erstellt",
            "expires": "Ablaufend",
            "account-manager": "Kundenbetreuer",
            "contact-emails": "Kontact-Emails",
            "nodes-allowed": "Erlaubte Knoten",
            "nodes-count": "Knotenanzahl",
            "cluster-name": "Cluster Name",
            "cluster-id": "Cluster ID",
            "nodes": "Knoten",
            "name": "Name",
            "distribution": "Distribution",
            "drbd-version": "DRBD-Version",
            "proxy-version": "Proxy-Version",
            "add-cluster": "Cluster hinzufügen",
            "edit-cluster": "Cluster editieren",
            "add-node": "Knoten hinzufügen",
            "edit-node": "Knoten editieren",
            "delete-node": "Knoten löschen",
            "download-packages": "Packete runterladen",
            "download-license": "Lizenz runterladen",
            "owner": "Besitzer",
            "type": "Type",
            "issued": "Ausgestellt",
            "create": "Erstellen",
            "sales-manager": "Ihr Verkaufsmanager ist",
            "phone": "Telefon",
            "tickets": "Tickets",
            "edit-contacts": "Kontakte für Vertrag ändern",
            "really-delete-node": "Knoten sicher löschen?",
            "really-delete-node-text": "Der Knoten wird vom Cluster und Vertrag gelöscht.",
            "licenses": "Lizenzen",
            "hide-empty": "Leere verstecken"
        },
	"edit-cluster": {
            "add": "Cluster zu Vertrag hinzufügen",
            "edit": "Cluster editieren",
            "cluster-name": "Cluster Name"
        },
        "edit-node": {
            "add": "Knoten zu Cluster hinzufügen",
            "hostname": "Hostname",
            "mac-addresses": "MAC Addressen",
            "ib-guids": "IB GUIDs"
        },
        "register-nodes": {
            "register-nodes": "Knoten registrieren",
            "header": "Um Ihre Knoten zu verwalten, laden Sie unser linbit-manager-node Kommandozeilenwerkzeug herunter",
            "download-button-text": "Kommandozeilenwerkzeug herunterladen",
            "we-invite-you": "Wir laden Sie ein unser <1>commandline tool</1> zu verwenden um das Repository zu konfigurieren und den Knoten in der Datenbank von LINBIT zu registrieren.",
            "to-use-it-save": "Um das Programm zu verwenden, speichern Sie die Datei (zum Beispiel nach /tmp) und machen Sie die Datei ausführbar:",
            "interactive-mode": "Interaktiver Modus (Standard):",
            "non-interactive-mode": "Nicht-Interaktiver Modus:",
            "non-interactive-description": "Aktuell ist dieser Modus auf das Herunterladen der zuletzt ausgestellten drbd-proxy Lizenz beschränkt. Nachfolgendes sollte als Benutzer root in einem cron-job ausgeführt werden.",
            "script-description": "(Ersetzen Sie /tmp durch das Verzeichnis in das Sie das Tool gespeichert haben). Bitte beachten Sie dass das Skript Python Version 2.6 (oder höher) vorraussetzt (mit python --version können Sie die Python version anzeigen)."
        },
        "account": {
            "account": "Konto",
            "change-password": "Passwort ändern",
            "old-password": "Altes Passwort",
            "new-password": "Neues Passwort",
            "retype-password": "Password wiederholen",
            "short-password": "Passwort muss aus mindestens 8 Zeichen bestehen",
            "password-must-match": "Passwörter stimmen nicht überein",
            "password-changed": "Passwort erfolgreich geändert."
        },
        "support": {
            "support": "Support",
            "ticket-paragraph": "Beim Absenden eines Tickets fügen Sie bitte ein komprimiertes TAR-File (tgz) mit dem syslog, ha-log und relevanten Konfigurationsdateien hinzu. (Dies wären /etc/drbd.d/* und /etc/ha.d/*, ebenso alle dazupassenden Daemon-Konfigurationen wie zB. /etc/mysql/*).",
            "urgent-paragraph": "Beginnen Sie ein neues Ticket, wenn Sie eine dringende Anfrage haben. Wenn Sie eine solche dringende Anfrage per E-Mail öffnen, schreiben Sie eine neue E-Mail und antworten Sie nicht auf eine vorherige Nachricht. Neue Eingaben des Kunden zu einem bestehenden Ticket lösen keine Benachrichtigungen für LINBIT-Supportmitarbeiter außerhalb der Geschäftszeiten aus.",
            "email": "eMail",
            "open-new-tickets": "Sie können neue Supporttickets per eMail an <1>{{suppportMail}}</1>, erstellen, wenn Ihre eMail-Adresse als \"Kontaktadresse\" mit mindestens einem Vertrag verknüpft ist (siehe Vertragsliste).",
            "phone-support": "Telefon-Support",
            "general-notes": "Allgemeine Hinweise",
            "urgent-requests": "Dringende Anfragen",
            "office-hours": "Büro zeiten",
            "phone-support-text": "Sie haben {{supportTime}} Telefon support.",
            "no-phone-support-text": "Der 24/7 Telefon support ist für Ihre Verträge nicht verfügbar. Bitte kontaktieren Sie Ihren Kundenbetreuer für ein upgrade.",
            "choose-credential-title": "Support-Berechtigung auswählen",
            "select-list-description": "Bitte Supportberechtigung auswählen:",
            "ticket-system-title": "Web Ticket System",
            "ticket-system-text": "Nutzen Sie unsere <1>Ticket</1> Software um neue tickets zu erstellen.<br />Verwenden Sie ihre my.linbit.com eMail Zugangsdaten um sich anzumelden. Benutzername ist ihre verlinkte eMail Addresse.",
            "deprecated-ticket-system-title": "Altes Ticket System"
        },
        "downloads": {
            "downloads": "Downloads",
            "preferred-way": "Der bevorzugte Weg um die LINBIT Packetquellen zu konfigurieren ist über das <1>Linbit Register Nodes</1> Skript.",
            "manual-repo-config": "Manuale Packetquellen konfiguration (<strong>nicht empfohlen</strong>):",
            "node-name": "Knoten Name",
            "repository-url": "Repository URL",
            "config": "Konfiguration",
            "latest": "Neuesten"
        },
        "contact-us": {
            "contact-us": "Kontakt",
            "sales": "Vertrieb",
            "your-sales-manager": "Ihr Sales-Account-Manager ist {{accountManager}}, <3>{{number}}</3> und <5>{{accountManagerEmail}}</5>",
            "to-open-a-ticket": "Um ein Ticket zu öffnen, schicken Sie eine Email an <1>{{supportMail}}</1> von einer der folgenden Email-Adressen:"
        },
        "tickets": {
            "ticketnumber": "Ticketnummer",
            "subject": "Betreff",
            "accounted-time": "Aufgewändete Zeit",
            "status": "Status",
            "for-contract": "Ticket(s) für Vertrag"
        },
        "reset": {
            "title": "Passwort zurücksetzen Anfrage",
            "token": "Token",
            "set-password": "Passwort setzen",
            "request-reset": "Zurücksetz Anfrage",
            "request-reset-description": "Passwort rücksetzung für angegeben Email anfordern.<br />Sie bekommen eine Email mit einem Link um Ihr Passwort zurücksetzen zu können.",
            "reset-requested-title": "Passwort zurücksetzen angefordert",
            "reset-requested-message": "Eine Email wurde an die angegeben Addresse geschickt, bitte folgen Sie den Anweisungen.",
            "reset-password-description": "Mit einem korrekten Token können Sie hier ein neues Passwort setzen.",
            "reset-password-done-title": "Neues Passwort wurde gesetzt",
            "reset-password-done-message": "Sie können nun zur Login-Seite zurückgehen und sich mit dem neuen Passwort anmelden. Bitte beachten Sie das es bis zu 5 Minuten dauern kann bevor das neue Passwort im Ticketsystem funktioniert."
        },
        "login": {
            "title": "In Ihren LINBIT-account einloggen",
            "subtitle": "Benutzen Sie Ihr LINBIT Kunden-Portal Email und Passwort",
            "keep-logged-in": "Für 7en Tage eingeloggt bleiben.",
            "username": "Email",
            "password": "Passwort",
            "login": "Einloggen",
            "reset-password": "Passwort zurücksetzen?"
        }
    }
}
