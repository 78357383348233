import * as React from 'react';
import {
    Alert, PageSection, Spinner, Title, BackToTop, Button, Split, SplitItem
} from "@patternfly/react-core"
import { useQuery } from "@tanstack/react-query"

import { apiGet, Ticket, TicketsResponse } from 'linbit-api-fetcher'
import { useNavigate, useParams } from 'react-router-dom';
import { Table /* data-codemods */, Thead, Tr, Th, Tbody, Td, ThProps } from '@patternfly/react-table';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';


function formatTimeDuration(minutes: number): string {
    let hours = Math.floor(minutes / 60);
    let mins = minutes - hours * 60;

    var s = ""
    if (hours) {
        s += hours + "h"
    }
    s += mins > 0 ? `${mins}`.padStart(2, "0") : "0";
    return s + "m";
}

interface TicketTableProps {
    tickets: Ticket[]
}

const TicketTable: React.FunctionComponent<TicketTableProps> = (props: TicketTableProps) => {
    const urlSourceMap: {[key: number]: string}  = {
        1: "https://support.linbit.com/otrs/customer.pl?Action=CustomerTicketZoom;TicketNumber=",
        2: "https://tickets.linbit.com/external/ticket/number/"
    }
    const [activeSortIndex, setActiveSortIndex] = React.useState<number>(0);

    // Sort direction of the currently sorted column
    const [activeSortDirection, setActiveSortDirection] = React.useState<'asc' | 'desc'>('asc');

    const getSortParams = (columnIndex: number): ThProps['sort'] => ({
        sortBy: {
            index: activeSortIndex,
            direction: activeSortDirection
        },
        onSort: (_event, index, direction) => {
            setActiveSortIndex(index);
            setActiveSortDirection(direction);
        },
        columnIndex
    });

    function renderTicketRows(tickets: Ticket[]) {
        return tickets.sort((a, b) => {
            if (activeSortIndex !== null) {
                if (activeSortIndex === 2) {
                    return activeSortDirection === 'asc' ? a.time_unit - b.time_unit : b.time_unit - a.time_unit;
                } else if (activeSortIndex === 3) {
                    const aStatus = a.status ? a.status : "";
                    const bStatus = b.status ? b.status : "";
                    return activeSortDirection === 'asc' ?
                        aStatus.localeCompare(bStatus) : bStatus.localeCompare(aStatus);
                }
            }
            return activeSortDirection === 'asc' ?
                a.ticket_number.localeCompare(b.ticket_number) : b.ticket_number.localeCompare(a.ticket_number);
        }).map((t) => {
            return (
                <Tr key={t.otrs_id}>
                    <Td style={{ fontFamily: "RedHatMono" }}>
                        <a
                            href={urlSourceMap[t.otrs_source] + t.ticket_number}
                            target="_blank"
                            rel="noopener noreferrer">
                            {t.ticket_number}
                        </a>
                    </Td>
                    <Td>{t.title}</Td>
                    <Td style={{ fontFamily: "RedHatMono", textAlign: "right" }}>{formatTimeDuration(t.time_unit)}</Td>
                    <Td>{t.status ? t.status : "unknown"}</Td>
                </Tr>)
        })
    }

    return (
        <Table
            variant='compact'
        // className={styles.smalltable}
        >
            <Thead>
                <Tr>
                    <Th width={10} sort={getSortParams(0)}>{t('tickets.ticketnumber')}</Th>
                    <Th>{t('tickets.subject')}</Th>
                    <Th sort={getSortParams(2)}>{t('tickets.accounted-time')}</Th>
                    <Th sort={getSortParams(3)}>{t('tickets.status')}</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderTicketRows(props.tickets)}
            </Tbody>
        </Table>
    )
}

const ContractTicketPage: React.FunctionComponent = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const contractId = params.contractId ? parseInt(params.contractId) : 0;

    async function fetchTickets(contractId: number): Promise<TicketsResponse> {
        let uri = '/my/contracts/' + contractId + '/tickets';
        return apiGet<TicketsResponse>(uri);
    }

    const { isLoading, isError, data, error } =
        useQuery<TicketsResponse, string>([contractId, 'tickets'], () => fetchTickets(contractId));

    if (isError) {
        return (<PageSection>
            <Alert variant="danger" title={error} /><br />
            <Button variant='secondary' onClick={() => navigate(-1)}>{t('common.back')}</Button>
        </PageSection>);
    }

    const ticketCount = data ? data.count : 0;
    const tickets = data ? data.list : [];
    return (
        <React.Fragment>
            <PageSection hasOverflowScroll name="scrolling-section">
                <Split>
                    <SplitItem isFilled>
                        <Title
                            id="title-ticket-head"
                            headingLevel="h1"
                            size="xl">{ticketCount} {t('tickets.for-contract')} {contractId}</Title>
                    </SplitItem>
                    <SplitItem><Button variant='secondary' onClick={() => navigate(-1)}>{t('common.back')}</Button></SplitItem>
                </Split>
                <br />
                {isLoading ? <Spinner  /> : <TicketTable tickets={tickets} />}
            </PageSection>
            <BackToTop scrollableSelector='[name="scrolling-section"]' />
        </React.Fragment>)
}

export {
    ContractTicketPage
}
