"use strict";
exports.__esModule = true;
require('./form.css');
exports.default = {
  "dirRtl": "pf-v5-m-dir-rtl",
  "form": "pf-v5-c-form",
  "formActions": "pf-v5-c-form__actions",
  "formFieldGroup": "pf-v5-c-form__field-group",
  "formFieldGroupBody": "pf-v5-c-form__field-group-body",
  "formFieldGroupHeader": "pf-v5-c-form__field-group-header",
  "formFieldGroupHeaderActions": "pf-v5-c-form__field-group-header-actions",
  "formFieldGroupHeaderDescription": "pf-v5-c-form__field-group-header-description",
  "formFieldGroupHeaderMain": "pf-v5-c-form__field-group-header-main",
  "formFieldGroupHeaderTitle": "pf-v5-c-form__field-group-header-title",
  "formFieldGroupHeaderTitleText": "pf-v5-c-form__field-group-header-title-text",
  "formFieldGroupToggle": "pf-v5-c-form__field-group-toggle",
  "formFieldGroupToggleButton": "pf-v5-c-form__field-group-toggle-button",
  "formFieldGroupToggleIcon": "pf-v5-c-form__field-group-toggle-icon",
  "formFieldset": "pf-v5-c-form__fieldset",
  "formGroup": "pf-v5-c-form__group",
  "formGroupControl": "pf-v5-c-form__group-control",
  "formGroupLabel": "pf-v5-c-form__group-label",
  "formGroupLabelHelp": "pf-v5-c-form__group-label-help",
  "formGroupLabelInfo": "pf-v5-c-form__group-label-info",
  "formGroupLabelMain": "pf-v5-c-form__group-label-main",
  "formHelperText": "pf-v5-c-form__helper-text",
  "formHelperTextIcon": "pf-v5-c-form__helper-text-icon",
  "formLabel": "pf-v5-c-form__label",
  "formLabelRequired": "pf-v5-c-form__label-required",
  "formLabelText": "pf-v5-c-form__label-text",
  "formSection": "pf-v5-c-form__section",
  "formSectionTitle": "pf-v5-c-form__section-title",
  "modifiers": {
    "horizontal": "pf-m-horizontal",
    "alignRight": "pf-m-align-right",
    "noPaddingTop": "pf-m-no-padding-top",
    "horizontalOnXs": "pf-m-horizontal-on-xs",
    "horizontalOnSm": "pf-m-horizontal-on-sm",
    "horizontalOnMd": "pf-m-horizontal-on-md",
    "horizontalOnLg": "pf-m-horizontal-on-lg",
    "horizontalOnXl": "pf-m-horizontal-on-xl",
    "horizontalOn_2xl": "pf-m-horizontal-on-2xl",
    "limitWidth": "pf-m-limit-width",
    "action": "pf-m-action",
    "info": "pf-m-info",
    "disabled": "pf-m-disabled",
    "inline": "pf-m-inline",
    "stack": "pf-m-stack",
    "error": "pf-m-error",
    "success": "pf-m-success",
    "warning": "pf-m-warning",
    "inactive": "pf-m-inactive",
    "hidden": "pf-m-hidden",
    "expanded": "pf-m-expanded"
  },
  "themeDark": "pf-v5-theme-dark"
};