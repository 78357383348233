import { createIcon } from '../createIcon';

export const MinusIconConfig = {
  name: 'MinusIcon',
  height: 512,
  width: 448,
  svgPath: 'M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z',
  yOffset: 0,
  xOffset: 0,
};

export const MinusIcon = createIcon(MinusIconConfig);

export default MinusIcon;