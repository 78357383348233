import * as React from 'react';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	List,
	ListItem,
	ListVariant,
	Masthead,
	MastheadBrand,
	MastheadContent,
	MastheadMain,
	Nav,
	NavItem,
	NavList,
	Page,
	Toolbar,
	ToolbarContent,
	ToolbarGroup,
	ToolbarItem
} from '@patternfly/react-core';

// dropdown isPlain style doesn't work yet
import {
	Dropdown,
	DropdownGroup,
	DropdownItem,
	DropdownToggle,

} from '@patternfly/react-core/deprecated';

import imgAvatar from '../assets/images/avatarImg.svg';
import logo from '../assets/images/linbit-logo-white.png';
import { apiGet, parseJwt } from 'linbit-api-fetcher';
import { MyAccountInfo } from 'linbit-api-fetcher/dist/structs';
import { useQuery } from '@tanstack/react-query';


interface MyPageHeaderProps {
  headerToolbar?: JSX.Element;
}

const MyPageHeader: React.FunctionComponent<MyPageHeaderProps> = (props: MyPageHeaderProps) => {
  const { t, i18n } = useTranslation();

  const [isLanguageDDOpen, setIsLanguageDDOpen] = React.useState(false);

  const LogoImg = () => {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/', { replace: true });
    }
    return (
      <img src={logo} onClick={handleClick} style={{ height: 30, width: 140 }} alt="Linbit Logo" />
    );
  }

  const onLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
  }

  const onLanguageSelect = (event: React.SyntheticEvent<HTMLDivElement, Event> | undefined) => {
    if (event && event.currentTarget && event.currentTarget.textContent) {
      onLanguageChange(event.currentTarget.id);
      setIsLanguageDDOpen(false);
    }
  }

  const languageMap: { [key: string]: string } = {
    "en": "English",
    "de": "Deutsch",
    "ja": "日本語"
  }

  const languageDropdownItems = [
    <DropdownItem id="en" key="en">{languageMap["en"]}</DropdownItem>,
    <DropdownItem id="de" key="de">{languageMap["de"]}</DropdownItem>,
    <DropdownItem id="ja" key="ja">{languageMap["ja"]}</DropdownItem>,
  ];

  return (
    <Masthead display={{ default: 'stack' }}>
      <MastheadMain>
        <MastheadBrand component="a" style={{ paddingRight: "30px" }}><LogoImg /></MastheadBrand>
        <List variant={ListVariant.inline} style={{ fontSize: "0.8em" }}>
          <ListItem><NavLink id="contact-us-link" style={{ color: "var(--pf-v5-c-nav__link--Color)" }} to="/contact">{t("contact-us.contact-us")}</NavLink></ListItem>
          <ListItem><a style={{ color: "var(--pf-v5-c-nav__link--Color)" }} href="tel:+43181782920">+43-1-8178292-0</a></ListItem>
          <ListItem><a style={{ color: "var(--pf-v5-c-nav__link--Color)" }} href="tel:+18774546248">+1-877-4LINBIT (454-6248)</a></ListItem>
          <ListItem><a style={{ color: "var(--pf-v5-c-nav__link--Color)" }} href="mailto:sales@linbit.com">sales@linbit.com</a></ListItem>
        </List>
        <div style={{ marginLeft: "auto", marginRight: "0", paddingTop: "0px", textAlign: "center" }}>
          <Dropdown
            isOpen={isLanguageDDOpen}
            alignments={{
              sm: 'right',
              md: 'right',
              lg: 'right',
              xl: 'right',
              '2xl': 'right'
            }}
            isPlain
            toggle={<DropdownToggle
              id="toggle-language"
              onToggle={(_event, isOpen: boolean) => setIsLanguageDDOpen(isOpen)}>{languageMap[i18n.language]}
            </DropdownToggle>}
            dropdownItems={languageDropdownItems}
            onSelect={onLanguageSelect} />
        </div>
      </MastheadMain>
      <MastheadContent>{props.headerToolbar ? props.headerToolbar : null}</MastheadContent>
    </Masthead>
  )
}

interface AppProps {
  handleLogout: (event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent) => void;
}

const App: React.FunctionComponent<AppProps> = (props: AppProps) => {
  const { t, i18n } = useTranslation();

  const [userDropdownOpen, setUserDropdownOpen] = React.useState(false);
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [isNavOpenMobile, setIsNavOpenMobile] = React.useState(false);

  const [user, setUser] = React.useState('unknown');
  const [companyName, setCompanyName] = React.useState('?');

  const STORAGE_SIDEBAR = "sidebar-state"

  React.useEffect(() => {
    let token = localStorage.getItem('access-token');
    if (token) {
      let jwt_data = parseJwt(token);
      if (jwt_data) {
        setUser(jwt_data['sub']);
      }
    }

    let sidebarState = localStorage.getItem(STORAGE_SIDEBAR);
    if (sidebarState) {
      if (sidebarState === "open") {
        setIsNavOpen(true);
        setIsNavOpenMobile(true);
      } else {
        setIsNavOpen(false);
        setIsNavOpenMobile(false);
      }
    }
  }, []);

  async function fetchMyAccountInfo(): Promise<MyAccountInfo> {
    let uri = '/my/account/info';
    return apiGet<MyAccountInfo>(uri);
  }

  const LogoImg = () => {
    const navigate = useNavigate();
    function handleClick() {
      navigate('/', { replace: true });
    }
    return (
      <img src={logo} onClick={handleClick} style={{ height: 30, width: 140 }} alt="Linbit Logo" />
    );
  }

  const onLanguageChange = (value: string) => {
    i18n.changeLanguage(value);
  }

  const onPageResize = (props: { mobileView: boolean; windowSize: number }) => {
    setIsMobileView(props.mobileView);
  };

  const onNavToggleMobile = () => {
    setIsNavOpenMobile(!isNavOpenMobile);
    localStorage.setItem(STORAGE_SIDEBAR, !isNavOpenMobile ? "open" : "closed");
  }

  const onNavToggleDesktop = () => {
    setIsNavOpen(!isNavOpen);
    localStorage.setItem(STORAGE_SIDEBAR, !isNavOpen ? "open" : "closed");
  }

  const { data: myInfo } =
    useQuery<MyAccountInfo, string>(['my', 'account', 'info'], fetchMyAccountInfo);

  if (myInfo && companyName == "?") {
    setCompanyName(myInfo.company_name);
  }

  const userDropDownItems = [
    <DropdownGroup key="user-drop">
      <DropdownItem key="user-drop-logout" onClick={props.handleLogout}>Logout</DropdownItem>
    </DropdownGroup>
  ];

  const headerToolbar = (
    <Toolbar id="toolbar" isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarItem>
          <Nav id="nav-primary-simple" theme="dark" variant='horizontal'>
            <NavList id="nav-list-simple">
              <NavItem key="contracts" id="contracts">
                <NavLink id="contracts-link" to="/contracts">{t('contracts.contracts')}</NavLink>
              </NavItem>
              <NavItem key="register-nodes" id="register-nodes">
                <NavLink id="register-nodes-link" to="/register-nodes">{t('register-nodes.register-nodes')}</NavLink>
              </NavItem>
              <NavItem key="account" id="account">
                <NavLink id="account-link" to="/account">{t('account.account')}</NavLink>
              </NavItem>
              <NavItem key="support" id="support">
                <NavLink id="support-link" to="/support">{t('support.support')}</NavLink>
              </NavItem>
              <NavItem key="downloads" id="downloads">
                <NavLink id="downloads-link" to="/downloads">{t('downloads.downloads')}</NavLink>
              </NavItem>
              <NavItem key="contact-us" id="contact-us">
                <NavLink id="contact-us-link" to="/contact">{t('contact-us.contact-us')}</NavLink>
              </NavItem>
            </NavList>
          </Nav>
        </ToolbarItem>
        <ToolbarGroup
          variant="icon-button-group"
          align={{ default: 'alignRight' }}
          spacer={{ default: 'spacerNone', md: 'spacerMd' }}
        >
          <ToolbarItem>
            <Dropdown
              position='right'
              isFullHeight
              isOpen={userDropdownOpen}
              toggle={
                <DropdownToggle
                  icon={<Avatar src={imgAvatar} alt="Avatar" />}
                  onToggle={(_event, isOpen: boolean) => setUserDropdownOpen(isOpen)}>
                  <p style={{ fontSize: "0.8em", lineHeight: "140%" }}>{user}<br />{companyName}</p>
                </DropdownToggle>}
              dropdownItems={userDropDownItems} />
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  return (
    <Page
      mainContainerId='primary-app-container'
      header={<MyPageHeader headerToolbar={headerToolbar} />}
      onPageResize={(_event, props: { mobileView: boolean; windowSize: number }) => onPageResize(props)} >
      <Outlet />
    </Page>
  );
}

export { App, MyPageHeader }
