import { createIcon } from '../createIcon';

export const WarningTriangleIconConfig = {
  name: 'WarningTriangleIcon',
  height: 1024,
  width: 1088,
  svgPath: 'M1057.10141,663.5 L845.101405,215.4 C787.101405,71.8 665.401405,0 542.901405,0 C420.201405,0 296.701405,71.9 235.001405,215.6 L31.7014051,648.5 C10.4014051,700 -0.0985948775,752.3 0.000697596367,800.8 C0.301405123,924.8 70.2014051,1024 209.101405,1024 L868.401405,1024 C1005.80141,1024 1087.70141,918.6 1088.00215,795.5 C1088.10141,752.4 1078.20141,707.2 1057.10141,663.5 Z M959.401405,800.3 C958.701405,822.9 952.901405,843.5 942.601405,859.7 C926.801405,884.6 902.601405,896.7 868.301405,896.7 L209.101405,896.7 C191.201405,896.7 176.601405,893.8 165.401405,888.2 C157.301405,884 150.801405,878.4 145.401405,870.3 C135.101405,855 129.101405,832 128.401405,805.6 C127.601405,772.8 134.901405,736.5 149.401405,700.5 L353.001405,266.7 C363.201405,242.9 376.101405,221.5 391.101405,203.2 C404.801405,186.6 420.301405,172.4 437.401405,161.1 C469.201405,139.9 505.701405,128.8 542.901405,128.8 C579.701405,128.8 615.401405,139.8 646.001405,160.5 C662.401405,171.6 677.101405,185.4 690.101405,201.6 C704.501405,219.6 716.401405,240.6 725.901405,264 L940.901405,718.9 L941.101405,719.3 L941.301405,719.7 C953.901405,746 960.201405,773.9 959.401405,800.3 Z M586.601405,832 L501.301405,832 C489.501405,831.8 480.201405,821.5 480.001405,808.7 L480.001405,727.3 C480.201405,714.5 489.601405,704.3 501.301405,704 L586.601405,704 C598.401405,704.2 607.701405,714.5 607.901405,727.3 L607.901405,808.7 L608.001405,808.7 C607.701405,821.5 598.301405,831.8 586.601405,832 M639.901405,290.7 L613.201405,610.4 C611.801405,626.9 598.001405,640 581.301405,640 L506.601405,640 C490.001405,640 476.101405,627.2 474.701405,610.7 L448.101405,291 C446.501405,272.3 461.301405,256.3 480.001405,256.3 L608.001405,256 C626.701405,256 641.401405,272 639.901405,290.7',
  yOffset: 0,
  xOffset: 0,
};

export const WarningTriangleIcon = createIcon(WarningTriangleIconConfig);

export default WarningTriangleIcon;