import * as React from "react";
import {
	ActionGroup,
	Alert,
	AlertVariant,
	Button,
	Form,
	FormAlert,
	FormGroup,
	Page,
	PageSection,
	Text,
	TextInput,
	Title,
	ValidatedOptions
} from '@patternfly/react-core';

import { Trans, useTranslation } from 'react-i18next';
import { Link, useSearchParams } from "react-router-dom";
import { MyPageHeader } from "./app";
import { isEmailValid } from "./util";
import { Answer } from "linbit-api-fetcher";

enum ResetSteps {
    Request = 'request',
    SetPassword = 'setpassword'
}

interface StatusMessage {
    title: string;
    message: string;
    variant: AlertVariant;
}

interface LoadingPropsType {
    spinnerAriaValueText: string;
    spinnerAriaLabelledBy?: string;
    spinnerAriaLabel?: string;
    isLoading: boolean;
}

const RequestResetStep: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [statusMessage, setStatusMessage] = React.useState<StatusMessage | null>(null);
    const [inProgress, setInProgress] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailValidated, setEmailValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);

    React.useEffect(() => {
        const paramEmail = searchParams.get('email');
        if (paramEmail) {
            handleEmailChange(paramEmail);
        }
    }, [searchParams])

    const handleEmailChange = (value: string) => {
        setEmail(value);
        setEmailValidated(isEmailValid(value));
    };

    const onRequestReset = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setInProgress(true);
        await fetch(process.env.REACT_APP_API_HOST + '/my-request-reset', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: email })
        }).then((resp) => {
            if (resp.status == 200) {
                resp.json().then((answer: Answer<string>) => {
                    if (answer.error) {
                        setStatusMessage({
                            title: t('common.error'),
                            message: answer.error.message,
                            variant: AlertVariant.danger
                        })
                    } else {
                        setEmail('');
                        setEmailValidated(ValidatedOptions.default);
                        setStatusMessage({
                            title: t('reset.reset-requested-title'),
                            message: t('reset.reset-requested-message'),
                            variant: AlertVariant.success
                        })
                    }
                });
            } else {
                throw resp.statusText;
            }
        }).catch((reason) => {
            setStatusMessage({ title: t('common.error'), message: reason.toString(), variant: AlertVariant.danger })
        }).finally(() => {
            setInProgress(false);
        })
    }

    const primaryLoadingProps = {} as LoadingPropsType;
    primaryLoadingProps.spinnerAriaValueText = 'Loading';
    primaryLoadingProps.spinnerAriaLabelledBy = 'primary-loading-button';
    primaryLoadingProps.isLoading = inProgress;

    return (
        <React.Fragment>
            <Text><Trans i18nKey='reset.request-reset-description'/></Text><br />
            <Form className="centered" onSubmit={onRequestReset}>
                <FormGroup label={t('common.email')} fieldId='email' isRequired>
                    <TextInput id="email" aria-label="email input" type="email" isRequired
                        value={email} onChange={(_event, val) => handleEmailChange(val)} validated={emailValidated} />
                </FormGroup>
                {statusMessage && (
                    <FormAlert>
                        <Alert
                            variant={statusMessage.variant}
                            title={statusMessage.title}
                            isInline
                        >{statusMessage.message}</Alert>
                    </FormAlert>
                )}
                <ActionGroup>
                    <Button
                        type="submit"
                        variant="primary"
                        isDisabled={emailValidated !== ValidatedOptions.success || inProgress}
                        {...primaryLoadingProps}>{t('reset.request-reset')}</Button>
                    <Link to={`/`}><Button variant="secondary">{t('common.back')}</Button></Link>
                </ActionGroup>
            </Form>
        </React.Fragment>
    )
}

const SetPasswordStep: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const [statusMessage, setStatusMessage] = React.useState<StatusMessage | null>(null);
    const [inProgress, setInProgress] = React.useState(false);
    const ref: React.MutableRefObject<HTMLInputElement | null> = React.useRef(null);
    const [token, setToken] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [passwordValidated, setPasswordValidated] = React.useState<ValidatedOptions>(ValidatedOptions.default);
    const [showFinished, setShowFinished] = React.useState(false);

    React.useEffect(() => {
        const paramConfirm = searchParams.get('token');
        if (paramConfirm) {
            setToken(paramConfirm);
        }
        ref.current?.focus();
    }, [searchParams])

    const onPasswordChange = (value: string) => {
        setPassword(value);
        setPasswordValidated(value.length >= 8 ? ValidatedOptions.success : ValidatedOptions.error);
    };

    const onSetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setInProgress(true);
        await fetch(process.env.REACT_APP_API_HOST + '/my-reset-password', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: token, password: password })
        }).then((resp) => {
            if (resp.status == 200) {
                resp.json().then((answer: Answer<string>) => {
                    if (answer.error) {
                        setStatusMessage({
                            title: t('common.error'),
                            message: answer.error.message,
                            variant: AlertVariant.danger
                        })
                    } else {
                        setPassword('');
                        setPasswordValidated(ValidatedOptions.default);
                        setToken('');
                        setShowFinished(true);
                    }
                });
            } else {
                throw resp.statusText;
            }
        }).catch((reason) => {
            setStatusMessage({ title: t('common.error'), message: reason.toString(), variant: AlertVariant.danger })
        }).finally(() => setInProgress(false));
    }

    const isSubmitDisabled = () => {
        return passwordValidated !== ValidatedOptions.success || token.length < 10 || inProgress
    }

    const primaryLoadingProps = {} as LoadingPropsType;
    primaryLoadingProps.spinnerAriaValueText = 'Loading';
    primaryLoadingProps.spinnerAriaLabelledBy = 'primary-loading-button';
    primaryLoadingProps.isLoading = inProgress;

    return (
        showFinished ?
            <React.Fragment>
                <Title headingLevel={"h2"}>{t('reset.reset-password-done-title')}</Title><br />
                <Text>{t('reset.reset-password-done-message')}</Text><br />
                <Link to={`/`}><Button variant="secondary">{t('common.back')}</Button></Link>
            </React.Fragment>
            :
            <React.Fragment>
                <Text><Trans i18nKey='reset.reset-password-description'/></Text><br />
                <Form className="centered" onSubmit={onSetPassword}>
                    <FormGroup label={t('reset.token')} fieldId='confirm-code' isRequired>
                        <TextInput id="confirm-code" aria-label="confirm-code input" type="text" isRequired
                            value={token} onChange={(_event, val) => setToken(val)} />
                    </FormGroup>
                    <FormGroup label={t('account.new-password')} fieldId='password' isRequired>
                        <TextInput id="password" aria-label="email input" type="password" isRequired
                            value={password} onChange={(_event, value: string) => onPasswordChange(value)}
                            validated={passwordValidated}
                            minLength={8}
                            ref={ref}/>
                    </FormGroup>
                    {statusMessage && (
                        <FormAlert>
                            <Alert
                                variant={statusMessage.variant}
                                title={statusMessage.title}
                                isInline
                            >{statusMessage.message}</Alert>
                        </FormAlert>
                    )}
                    <ActionGroup>
                        <Button
                            type="submit"
                            variant="primary"
                            isDisabled={isSubmitDisabled()}
                            {...primaryLoadingProps}>{t('reset.set-password')}</Button>
                        <Link to={`/`}><Button variant="secondary">{t('common.back')}</Button></Link>
                    </ActionGroup>
                </Form>
            </React.Fragment>
    )
}

const ResetPasswordPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const step = searchParams.get('step') === 'setpassword' ? ResetSteps.SetPassword : ResetSteps.Request;

    const stepElem = step === ResetSteps.SetPassword ? <SetPasswordStep /> : <RequestResetStep />;
    return (
        <Page
            mainContainerId='primary-app-container'
            header={<MyPageHeader />}>
            <PageSection className="centered" variant="light">
                <Title headingLevel={"h1"}>{t('reset.title')}</Title><br />
                {stepElem}
            </PageSection>
        </Page>
    );
}

export { ResetPasswordPage }
