import * as React from 'react';
import {
  LoginForm,
  LoginMainFooterBandItem,
  LoginPage,
  ListVariant,
} from '@patternfly/react-core';
import ExclamationCircleIcon from '@patternfly/react-icons/dist/js/icons/exclamation-circle-icon';

import { Answer, LoginResponse } from 'linbit-api-fetcher';

import linbitLogo from 'url:/assets/images/linbit-logo-white.png';
import linbitSign from 'url:/assets/images/linbit-sign.svg';
import { useTranslation } from 'react-i18next';
interface AppLoginProps {
  handleLogin: (value: boolean) => void;
}

const AppLogin: React.FunctionComponent<AppLoginProps> = (props: AppLoginProps) => {
  const { t, i18n } = useTranslation();
  const defaultRememberLabel = "Keep me logged in for 7 days.";

  const [showHelperText, setShowHelperText] = React.useState(false);
  const [helperText, setHelperText] = React.useState('Invalid login credentials.');
  const [usernameValue, setUserNameValue] = React.useState('');
  const [isValidUsername, setIsValidUsername] = React.useState(true);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [isValidPassword, setIsValidPassword] = React.useState(true);
  const [isRememberMeChecked, setIsRememberMeChecked] = React.useState(true);
  const [rememberMeLabel, setRememberMeLabel] = React.useState(defaultRememberLabel);
  const [alreadyLoged, setAlreadyLoged] = React.useState(false);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = React.useState(false);

  const handleUsernameChange = (value: string) => {
    setUserNameValue(value);
  };

  const handlePasswordChange = (passwordValue: string) => {
    setPasswordValue(passwordValue);
  };

  const onRememberMeClick = () => {
    setIsRememberMeChecked(!isRememberMeChecked);
    if (isRememberMeChecked) {
      setRememberMeLabel("Auto logout after ~30mins");
    } else {
      setRememberMeLabel(defaultRememberLabel);
    }
  };

  const onLoginButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsValidUsername(!!usernameValue);
    setIsValidPassword(!!passwordValue);
    setShowHelperText(!usernameValue || !passwordValue);

    setIsLoginButtonDisabled(true);
    document.body.style.cursor = "progress";

    (async () => {
      const rawResponse =
        await fetch(process.env.REACT_APP_API_HOST + '/login', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "user": usernameValue,
            "pass": passwordValue,
            "domain": "my"
          })
        }).then(rawResponse => {
          if (rawResponse.status == 200) {
            rawResponse.json().then((answer: Answer<LoginResponse>) => {
              if (answer.data) {
                localStorage.setItem('access-token', answer.data.access_token);
                if (isRememberMeChecked) {
                  localStorage.setItem('refresh-token', answer.data.refresh_token);
                }
                localStorage.removeItem('original-access-token');
                localStorage.removeItem('original-refresh-token');
                props.handleLogin(true);
              }
            });
          } else if (rawResponse.status == 401) {
            setHelperText("Incorrect login credentials.");
            setShowHelperText(true);
          } else {
            setHelperText("Unable to reach api server.");
            setShowHelperText(true);
          }
        }).catch(error => {
          if (error == "TypeError: Failed to fetch") {
            setHelperText("CORS error, API doesn't allow this host origin.");
          } else {
            setHelperText("Unable to reach api server: " + process.env.REACT_APP_API_HOST + " " + error);
          }
          setShowHelperText(true);
        }).finally(() => {
          setIsLoginButtonDisabled(false);
          document.body.style.cursor = "default";
        });
    })();
  };

  React.useEffect(() => {
    let value = localStorage.getItem('access-token');
    if (value) {
      setAlreadyLoged(true);
    } else {
      setAlreadyLoged(false);
    }
  });

  const helperTextFragment = (
    <React.Fragment>
      <ExclamationCircleIcon />
      &nbsp;{helperText}
    </React.Fragment>
  );

  const forgotCredentials = (
    <LoginMainFooterBandItem>
      <a href="/reset-password">{t('login.reset-password')}</a>
    </LoginMainFooterBandItem>
  );

  const listItem = (
    <React.Fragment>
    </React.Fragment>
  );

  const loginForm = (
    <LoginForm
      showHelperText={showHelperText}
      helperText={helperTextFragment}
      usernameLabel={t('login.username')!}
      usernameValue={usernameValue}
      onChangeUsername={(_event, value: string) => handleUsernameChange(value)}
      isValidUsername={isValidUsername}
      passwordLabel={t('login.password')!}
      passwordValue={passwordValue}
      onChangePassword={(_event, passwordValue: string) => handlePasswordChange(passwordValue)}
      isValidPassword={isValidPassword}
      rememberMeLabel={rememberMeLabel}
      isRememberMeChecked={isRememberMeChecked}
      onChangeRememberMe={onRememberMeClick}
      onLoginButtonClick={onLoginButtonClick}
      isLoginButtonDisabled={isLoginButtonDisabled}
    />
  );

  return (
    alreadyLoged ?
      <p />
      :
      <LoginPage
        footerListVariants={ListVariant.inline}
        brandImgSrc={linbitLogo}
        brandImgAlt="Linbit logo"
        backgroundImgSrc={linbitSign}
        footerListItems={listItem}
        textContent="LINBIT Customer Portal"
        loginTitle={t('login.title')}
        loginSubtitle={t('login.subtitle')!}
        forgotCredentials={forgotCredentials}>
        {loginForm}
      </LoginPage>
  );
}

export { AppLogin };
