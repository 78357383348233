{
    "header": "LINBIT customer portal - Changelog",
    "versions": [
        {
            "version": "unreleased",
            "date": "2022-09-29",
            "added": [
                "initial version"
            ]
        }
    ]
}
