"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseJwt = exports.apiDelete = exports.apiPut = exports.apiPost = exports.apiGetBlob = exports.apiGet = exports.setupLAF = void 0;
const wretch_1 = require("wretch");
function forceLogout(msg) {
    console.log(msg);
    localStorage.removeItem('access-token');
    localStorage.removeItem('refresh-token');
    window.location.reload();
}
// wretch middlware
const accessMiddle = () => next => (url, opts) => {
    return new Promise(async (res, rej) => {
        let accToken = localStorage.getItem('access-token');
        var curExp = 0;
        if (accToken) {
            let jwt = parseJwt(accToken);
            curExp = jwt["exp"] * 1000;
        }
        else {
            console.error("no access-token, assuming expired");
        }
        if (curExp < Date.now()) {
            // maybe mutex here?
            console.log("access-token expired");
            let refToken = localStorage.getItem('refresh-token');
            if (!refToken) {
                forceLogout("no refresh-token available");
                rej("no refresh-token available");
                return;
            }
            let jwtRef = parseJwt(refToken);
            let curExp = jwtRef["exp"] * 1000;
            if (curExp < Date.now()) {
                forceLogout("refresh-token expired");
                rej("refresh-token expired");
                return;
            }
            else {
                console.log("refresh now");
                // do this request just with fetch API, to not trigger a recursion here
                await fetch(api._url + '/auth-refresh', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "refresh_token": refToken })
                })
                    .then((resp) => {
                    if (resp.status === 403) {
                        // forbidden token not valid
                        forceLogout("refresh token expired or error: " + refToken);
                        throw { message: "refresh token expired or error" };
                    }
                    return resp.json();
                })
                    .then((answer) => {
                    let loginanswer = answer;
                    if (loginanswer.data) {
                        console.log("access-token refreshed.");
                        var access_token = loginanswer.data?.access_token;
                        localStorage.setItem("access-token", access_token);
                        return access_token;
                    }
                    else {
                        forceLogout("refresh token expired or error");
                        throw { message: "refresh token expired or error" };
                    }
                })
                    .catch((error) => {
                    forceLogout("error doing auth-refresh: " + error);
                });
            }
        }
        // continue the request
        opts["headers"] = {
            ...opts["headers"],
            ...{ 'Authorization': 'Bearer ' + localStorage.getItem('access-token') }
        };
        res(next(url, opts));
    });
};
// Instantiate and configure wretch
let api = (0, wretch_1.default)("localhost", { mode: "cors" });
function setupLAF(base_url) {
    api = (0, wretch_1.default)(base_url, { mode: "cors" })
        .headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    })
        .middlewares([accessMiddle()]);
}
exports.setupLAF = setupLAF;
function checkResponseErrors(chain) {
    return chain.notFound((err, req) => {
        throw new Error("NotFound(" + err.status + "): " + req._url);
    }).unauthorized((err) => {
        forceLogout("unautorized logging out.");
        throw new Error("unauthorized");
    });
}
function parseJwt(token) {
    try {
        const tokenstr = Buffer.from(token.split('.')[1], 'base64').toString('utf-8');
        return JSON.parse(tokenstr);
    }
    catch (e) {
        console.error(e);
        return { sub: "unknown", exp: 0, role: "unknown", issuer_id: 0, may_issue_licenses: false };
    }
}
exports.parseJwt = parseJwt;
;
function onAnswer(ans) {
    let answer = ans;
    if (answer.data != undefined) {
        return Promise.resolve(answer.data);
    }
    else {
        if (answer.error != undefined) {
            let errorStr = "Error(" + answer.error.code + "): " + answer.error.message;
            return Promise.reject(errorStr);
        }
    }
    return Promise.reject("data undefined");
}
async function apiGet(path) {
    return checkResponseErrors(api.get(path))
        .json()
        .then(onAnswer)
        .catch((err) => {
        return Promise.reject(err.message ? err.message : err.toString());
    });
}
exports.apiGet = apiGet;
async function apiGetBlob(path) {
    return checkResponseErrors(api.get(path))
        .blob()
        .catch((err) => {
        return Promise.reject(err.message ? err.message : err.toString());
    });
}
exports.apiGetBlob = apiGetBlob;
async function apiPost(path, data) {
    return checkResponseErrors(api.post(data, path))
        .json()
        .then(onAnswer)
        .catch((err) => {
        return Promise.reject(err.message ? err.message : err.toString());
    });
}
exports.apiPost = apiPost;
async function apiPut(path, data) {
    return checkResponseErrors(api.put(data, path))
        .json()
        .then(onAnswer)
        .catch((err) => {
        return Promise.reject(err.message ? err.message : err.toString());
    });
}
exports.apiPut = apiPut;
async function apiDelete(path) {
    return checkResponseErrors(api.delete(path))
        .json()
        .then(onAnswer)
        .catch((err) => {
        return Promise.reject(err.message ? err.message : err.toString());
    });
}
exports.apiDelete = apiDelete;
