import { ValidatedOptions } from "@patternfly/react-core";

function isEmailValid(input: string): ValidatedOptions {
    if (input) {
        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (!input || regex.test(input) === false) {
            return ValidatedOptions.error;
        }
        return ValidatedOptions.success;
    }
    return ValidatedOptions.default;
}

export {
    isEmailValid
}
