import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import * as common_de from "./translations/de.json";
import * as common_en from "./translations/en.json";
import * as common_ja from "./translations/ja.json";

// the translations
const resources = {
    en: common_en,
    de: common_de,
    ja: common_ja
};

i18n
    .use(LanguageDetector) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
