.smalltable {
    --pf-c-table--m-compact--cell--PaddingTop: 0.15rem;
    --pf-c-table--m-compact--cell--PaddingBottom: 0.15rem;
}

.tinytable, .tinytable th, .tinytable td {
    --pf-c-table--m-compact--cell--PaddingTop: 0.15rem;
    --pf-c-table--m-compact--cell--PaddingBottom: 0.15rem;
    --pf-c-table--m-compact--cell--PaddingLeft: 0.15rem;
    --pf-c-table--m-compact--cell--PaddingRight: 0.15rem;
    --pf-c-table--m-compact--FontSize: 0.8rem;
    border: 1px solid black;
}

.smallpadding {
    --pf-c-button--PaddingTop: 0.05rem;
    --pf-c-button--PaddingBottom: 0.05rem;
    --pf-c-button--PaddingLeft: 0.4rem;
    --pf-c-button--PaddingRight: 0.4rem;
}
