"use strict";
exports.__esModule = true;
require('./split.css');
exports.default = {
  "modifiers": {
    "wrap": "pf-m-wrap",
    "fill": "pf-m-fill",
    "gutter": "pf-m-gutter"
  },
  "split": "pf-v5-l-split",
  "splitItem": "pf-v5-l-split__item"
};