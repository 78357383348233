import { useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    apiGet, ContractContactResponse, ContractResponse, Distribution, DistributionsResponse
} from "linbit-api-fetcher";

async function fetchContracts(contract_options?: number[]): Promise<ContractResponse> {
    let uri = '/my/contracts';
    if (contract_options !== undefined) {
        uri = uri + "?contract_options=" + contract_options.join(",");
    }
    return apiGet<ContractResponse>(uri);
}

function queryContracts(contract_options?: number[]): UseQueryResult<ContractResponse, string> {
    return useQuery<ContractResponse, string>(["contracts", contract_options], () => fetchContracts(contract_options));
}

type DistriLookup = { [key: number]: Distribution };

async function fetchDistributions(): Promise<DistriLookup> {
    let uri = '/my/distributions';
    return apiGet<DistributionsResponse>(uri)
        .then((distriResp) => {
            return distriResp.list
                .reduce((opts: DistriLookup, co) => {
                    opts[co.id] = co;
                    return opts;
                }, {});
        });
}

function queryDistributions(): UseQueryResult<DistriLookup, string> {
    return useQuery<DistriLookup, string>(['my', 'distributions'], fetchDistributions);
}

async function fetchContractContacts(contractId: number): Promise<ContractContactResponse> {
    let uri = '/my/contracts/' + contractId + '/contacts';
    return apiGet<ContractContactResponse>(uri);
}

function qkContractContacts(contractId: number) {
    return ['my', 'contracts', contractId + '', 'contacts'];
}

function queryContractContacts(contractId: number): UseQueryResult<ContractContactResponse, string> {
    return useQuery<ContractContactResponse, string>(
        qkContractContacts(contractId), () => fetchContractContacts(contractId));
}

export {
    queryContracts,
    queryDistributions,
    fetchContractContacts,
    queryContractContacts,
    qkContractContacts,
}

export type {
    DistriLookup
}
