import * as React from "react";
import * as changelogJson from "./changelog.json";

interface VersionEntry {
    version: string,
    date: string,
    added?: string[],
    changed?: string[],
    fixed?: string[],
    removed?: string[],
}

const ChangelogPage: React.FunctionComponent<{}> = () => {

    const renderList = (title: string, items?: string[]) => {
        if (items?.length) {
            return (
                <React.Fragment>
                    <h3>{title}</h3>
                    <ul>
                        {items.map((i) => <li>{i}</li>)}
                    </ul>
                </React.Fragment>
            )
        }
        return;
    }

    const renderVersion = (data: VersionEntry) => {
        if (data.added?.length || data.changed?.length || data.fixed?.length || data.removed?.length) {
            return (
                <React.Fragment>
                    <h2>[{data.version}] - {data.date}</h2>
                    <hr /><br />
                    {renderList("Added", data.added)}
                    {renderList("Changed", data.changed)}
                    {renderList("Fixed", data.fixed)}
                    {renderList("Removed", data.removed)}
                </React.Fragment>
            )
        }
        return;
    }

    return (
        <React.Fragment>
            <h1>{changelogJson.header}</h1><br />
            {changelogJson.versions.map((v) => renderVersion(v))}
        </React.Fragment>
    )
}

export { ChangelogPage };
