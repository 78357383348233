import { Title, Text, TextVariants, PageSection, Modal, Button, SimpleList, SimpleListItem, ModalVariant } from "@patternfly/react-core";
import { useQuery } from "@tanstack/react-query";
import { apiGet, apiPost } from "linbit-api-fetcher";
import { MyAccountInfo, CreateOTRSSession, OTRSSession } from "linbit-api-fetcher/dist/structs";
import * as React from "react";
import { Trans, useTranslation } from 'react-i18next';

const PhoneSupportSection: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();

    async function fetchMyAccountInfo(): Promise<MyAccountInfo> {
        let uri = '/my/account/info';
        return apiGet<MyAccountInfo>(uri);
    }

    const { data: myInfo } =
        useQuery<MyAccountInfo, string>(['my', 'account', 'info'], fetchMyAccountInfo);

    const phoneSupportKey = myInfo?.support_level ? 'support.phone-support-text' : 'support.no-phone-support-text';
    const supportTime = myInfo?.support_level === "24/7" ? myInfo.support_level : t('support.office-hours');
    const supportNumbers = myInfo?.phone_numbers;

    return (
        <React.Fragment>
            <Title headingLevel="h1">{t('support.phone-support')}</Title>
            <Text component={TextVariants.p}><Trans i18nKey={phoneSupportKey}>
                You have {{ supportTime }} telephone support.
            </Trans> {supportNumbers ? supportNumbers
                .map(n => <a href={"tel:" + n}>{n}</a>)
                .map((t, index) =>
                    <React.Fragment key={index}>
                        {t}{index !== supportNumbers.length - 1 ? ' or ' : null}
                    </React.Fragment>)
                : null}
            </Text>
        </React.Fragment >
    )
}

const SupportPage: React.FunctionComponent<{}> = () => {
    const { t, i18n } = useTranslation();
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const openOTRSSession = (credential: string) => {
        document.body.style.cursor = "progress";
        let req: CreateOTRSSession = {
            credential: credential
        }
        apiPost<OTRSSession>('/my/account/otrs-session', req)
            .then(resp => {
                window.open("https://support.linbit.com/cgi-bin/session-cookie.sh?CSID=" + resp.csid);
            })
            .catch(reason => {
                console.error(reason);
            })
            .finally(() => document.body.style.cursor = "default")
    }

    const loginOTRS = () => {
        if (myInfo && myInfo.support_credentials.length > 0) {
            if (myInfo.support_credentials.length > 1) {
                setIsModalOpen(true);
            } else {
                openOTRSSession(myInfo.support_credentials[0]);
            }
        }
    }

    async function fetchMyAccountInfo(): Promise<MyAccountInfo> {
        let uri = '/my/account/info';
        return apiGet<MyAccountInfo>(uri);
    }

    const { data: myInfo } =
        useQuery<MyAccountInfo, string>(['my', 'account', 'info'], fetchMyAccountInfo);

    const suppportMail = "tickets@linbit.com";
    return (
        <PageSection className="centered" variant="light">
            <Modal
                title={t('support.choose-credential-title')!}
                variant={ModalVariant.small}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                actions={[
                    <Button key="cancel" variant="link" onClick={() => setIsModalOpen(false)}>
                        {t('common.cancel')}
                    </Button>
                ]}
            >
                <Text component={TextVariants.p}>{t('support.select-list-description')}</Text><br />
                <SimpleList aria-label="Credential selection">
                    {myInfo && myInfo.support_credentials
                        .map(c => <SimpleListItem
                            key={c}
                            onClick={() => { openOTRSSession(c); setIsModalOpen(false) }}>{c}</SimpleListItem>)}
                </SimpleList>
            </Modal>
            <Title headingLevel="h1" style={{ textAlign: "center" }}>{t('support.support')}</Title><br />
            <Title headingLevel="h2">{t('support.general-notes')}</Title>
            <Text component={TextVariants.p}>{t('support.ticket-paragraph')}</Text><br />
            <Title headingLevel="h2">{t('support.urgent-requests')}</Title>
            <Text component={TextVariants.p}>{t('support.urgent-paragraph')}</Text><br />
            <Title headingLevel="h2">{t('support.ticket-system-title')}</Title>
            <Text component={TextVariants.p}>
                <Trans i18nKey="support.ticket-system-text">
                    We urge you to use our new <a href="https://tickets.linbit.com" target="_blank" rel="noopener noreferrer">ticketing</a> software.<br />
                    The plan is to discontinue our old system on 31.12.2023.
                </Trans>
            </Text><br />
            <Title headingLevel="h2">{t('support.email')}</Title>
            <Text component={TextVariants.p}>
                <Trans i18nKey='support.open-new-tickets'>
                    You can open new tickets by eMail to <a href={"mailto: " + suppportMail}>{{ suppportMail } as any}</a>,
                    if your eMail address is listed as a "contact eMail" with at least one contract, see the contracts tab.
                    (E-mails directed to <a href="mailto: support@linbit.com">support@linbit.com</a> open a ticket in the old system until end of 2023)
                </Trans>
            </Text><br />
            <PhoneSupportSection />
        </PageSection>
    )
}

export { SupportPage, PhoneSupportSection }
